import React, { useState } from 'react';
import { Link } from 'gatsby';

import {
  mainNavbar,
  navLinks,
  navLinkItem,
  navLinkText,
  logo,
  hamburgerIcon,
  // dropdownIcon,
  requestDemo,
  sidenav,
  closebtn,
  xNavIcon,
  hamburgerIconWrap,
} from './navbar.module.scss';
// import DropdownIcon from '../icons/dropdownIcon';
import DDLogoWithText from '../icons/ddLogoWithText';
import HamburgerIcon from '../icons/hamburgerIcon';
// import SideNavbar from './sideNavbar';

import XNavIcon from '../icons/xNavIcon';

function MainNavbar() {
  const [sideNavOpen, setsideNavOpen] = useState(false);
  const openSideNavbar = () => {
    console.log('opne side nav clicked');
    setsideNavOpen(true);
    // document.getElementById('mySidenav').style.width = '250px';
  };
  const closeSideNavbar = () => {
    setsideNavOpen(false);
    // document.getElementById('mySidenav').style.width = '0px';
  };

  return (
    <div className={mainNavbar}>
      <Link to='/'>
        <DDLogoWithText className={logo} />
      </Link>
      <ul className={navLinks}>
        <li className={navLinkItem}>
          <Link className={navLinkText} to='/pricing'>
            Pricing
          </Link>
        </li>
        <li className={navLinkItem}>
          <Link className={navLinkText} to='/about'>
            About Us
          </Link>
        </li>
        <li className={(navLinkItem, requestDemo)}>
          <Link className={navLinkText} to='/contact'>
            REQUEST FREE DEMO
          </Link>
        </li>
      </ul>
      <span className={hamburgerIconWrap} onClick={openSideNavbar}>
        <HamburgerIcon className={hamburgerIcon} />
      </span>
      <div id='mySidenav' className={sideNavOpen ? sidenav : 'display-none'}>
        <div onClick={closeSideNavbar} className={closebtn}>
          <XNavIcon className={xNavIcon} />
        </div>
        <ul>
          <li>
            <Link className={navLinkText} to='/pricing'>
              Pricing
            </Link>
          </li>
          <li>
            <Link className={navLinkText} to='/about'>
              About Us
            </Link>
          </li>
          <li>
            <Link to='/support' className={navLinkText}>
              Support
            </Link>
          </li>
          <li>
            <Link className={(navLinkText, requestDemo)} to='/contact'>
              REQUEST FREE DEMO
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default MainNavbar;
