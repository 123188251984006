import * as React from 'react';
import Navbar from './navbar/navbar';
import Footer from './footer/footer';
import { container, content } from './layout.module.scss';

import indexStyles from '../styles/index.scss';

const Layout = ({ children }) => {
  return (
    <div className={container}>
      <div className={content}>
        <Navbar />
        {children}
      </div>
      <React.Fragment>
        <Footer />
      </React.Fragment>
    </div>
  );
};

export default Layout;
