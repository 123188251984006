import React from 'react';

function XNavIcon({ className }) {
  return (
    <svg
      className={className}
      fill='#2d3b5f'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      clipRule='evenodd'
    >
      <path d='M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z' />
    </svg>
  );
}

export default XNavIcon;
