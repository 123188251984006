import React from 'react';
import { Link } from 'gatsby';
import { logo } from '../../components/navbar/navbar.module.scss';

import {
  footer,
  footerGrid,
  footerLogoCol,
  footerCompanyCol,
  footerResourceCol,
  footerLegalCol,
  copyrightSection,
} from './footer.module.scss';

import DDLogo from '../icons/ddLogo';

const Footer = () => {
  return (
    <div className={footer}>
      <div className={footerGrid}>
        <div className={footerLogoCol}>
          <div>
            <Link to='/'>
              <DDLogo className={logo} />
            </Link>
          </div>
        </div>
        <div className={footerCompanyCol}>
          <div className='medium-font-heavy'>Company</div>
          <ul>
            <li>
              <Link className='lead-font-heavy' to='/about'>
                About us
              </Link>
            </li>
            <li>
              <Link className='lead-font-heavy' to='/'>
                Products
              </Link>
            </li>
            <li>
              <Link className='lead-font-heavy' to='/pricing'>
                Pricing
              </Link>
            </li>
          </ul>
        </div>
        <div className={footerResourceCol}>
          <div className='medium-font-heavy'>Resources</div>
          <ul>
            <li>
              <Link className='lead-font-heavy' to='/'>
                Blog
              </Link>
            </li>
            <li>
              <Link className='lead-font-heavy' to='/'>
                Webinar
              </Link>
            </li>
            <li>
              <Link className='lead-font-heavy' to='/'>
                Case Studies
              </Link>
            </li>
            <li>
              <Link className='lead-font-heavy' to='/'>
                Testimonials
              </Link>
            </li>
          </ul>
        </div>
        <div className={footerLegalCol}>
          <div className='medium-font-heavy'>Legal</div>
          <ul>
            <li>
              <Link className='lead-font-heavy' to='/privacy'>
                Licence
              </Link>
            </li>
            <li>
              <Link className='lead-font-heavy' to='/privacy'>
                Privacy policy
              </Link>
            </li>
            <li>
              <Link className='lead-font-heavy' to='/privacy'>
                Terms of service
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className={copyrightSection}>
        <p className='small-text'>
          © Daily Doc Technologies 2021. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
