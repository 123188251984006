import React from 'react';
import { Link } from 'gatsby';
import {
  topNavbar,
  topNavUl,
  topNavLi,
  topNavLiText,
} from './navbar.module.scss';

function Topnavbar() {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div className={topNavbar}>
      <ul className={topNavUl}>
        <li className={topNavLi}>
          <span className={topNavLiText}>9699569785</span>
        </li>
        <li className={topNavLi}>
          <Link to='/support' className={topNavLiText}>
            Support
          </Link>
        </li>
        <li className={topNavLi}>
          <span
            onClick={() => openInNewTab('https://www.dailydocapp.com/login')}
            className={topNavLiText}
          >
            Login
          </span>
        </li>
      </ul>
    </div>
  );
}

export default Topnavbar;
