import React from 'react';

import Topnavbar from './topnavbar';
import { navbar } from './navbar.module.scss';
import MainNavbar from './MainNavbar';

const Navbar = () => (
  <nav className={navbar}>
    <div className='large-container'>
      <Topnavbar />
      <MainNavbar />
    </div>
  </nav>
);

export default Navbar;
