import React from 'react';

function HamburgerIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='11'
      viewBox='0 0 25 11'
    >
      <g transform='translate(-7.5 -9)'>
        <line
          x2='25'
          transform='translate(7.5 9.5)'
          fill='none'
          stroke='#2d3b5f'
          strokeWidth='1'
        />
        <line
          x2='25'
          transform='translate(7.5 14.5)'
          fill='none'
          stroke='#2d3b5f'
          strokeWidth='1'
        />
        <line
          x2='25'
          transform='translate(7.5 19.5)'
          fill='none'
          stroke='#2d3b5f'
          strokeWidth='1'
        />
      </g>
    </svg>
  );
}

export default HamburgerIcon;
